.wrapper {
    width: 100%;
    height: 100%;
    background-color: white;
}

.line1 {
    width: 100%;
    height: 78px;
    position: relative;
}

.title {
    position: absolute;
    left: 40px;
    top: 36px;
    height: 42px;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 42px;
}

.balance {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #fd1b1b;
}

.warning {
    display: flex;
    align-items: center;
    margin-left: 40px;
    margin-top: 32px;
    color: #fd1b1b;
}

.warning svg {
    position: relative;
    bottom: 1px;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    flex-shrink: 0;
}

.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap-reverse;
}

.smallTitle {
    font-family: Roboto, sans-serif !important;
    font-style: normal;
    line-height: 21px;
}

.dropdownMenu {
    width: 280px;
    height: 40px;
    flex-shrink: 0;
    background: #f2f2f2;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border: none;
    margin-bottom: 16px;
}

.dropdownMenu option {
    margin-left: 10px;
}

.icon {
    margin-right: 5px;
}

.addressSuggestions {
    position: relative;
}

.addressSuggestions input {
    width: 100%;
    height: 28px;
    background: #f2f2f2;
    border-radius: 6px;
    padding: 15px;
    outline: none !important;
    border: none !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.addressSuggestions input:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none;
}
.form {
    position: relative;
    margin: 40px 0 0 40px !important;
    width: 40% !important;

    &_container {
        width: 100% !important;
    }

    &_blocker {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            width: 105%;

            height: 100%;
            background: rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            top: -10px;
            left: -2.5%;
        }
    }
}
