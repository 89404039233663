
.line1 {
        width: 100%;
        height: 78px;
        position: relative;
}

.title {
        position: absolute;
        left: 40px;
        top: 36px;
        height: 42px;
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 42px;
}
