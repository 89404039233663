.viewPopup {
    width: 500px;
    position: relative;
    padding: 40px 32px 0 32px;
}

.viewPopupClose {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.viewPopupTitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 40px;
    margin-left: 8px;
}

.form_input,
.form_textarea,
.form_checkbox input {
    height: 40px;

    &:focus {
        outline: none;
    }
}

.form_textarea {
    max-height: 222px;
    max-width: 100%;
}

.label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #b3b3b3;
}

.container {
    display: flex;
    flex-direction: column;
}

.datePicker {
    cursor: pointer;
    caret-color: transparent;
    height: 40px !important;
    width: 100% !important;
    visibility: visible !important;
    background: var(--color-gray) !important;
    color: var(--color-black) !important;
}

.datePicker:hover {
    background: #ebebeb !important;
    transition: 0.1s;
}

.disabled:hover {
    background: #afafaf !important;
}

.addressSuggestions {
    position: relative;
}

.addressSuggestions input {
    width: 100%;
    height: 40px;
    background: #f2f2f2;
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 16px;
    outline: none !important;
    border: none !important;
}

.addressSuggestions input:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none;
}

.disabled {
    opacity: 0.5 !important;
    background: #afafaf !important;
    cursor: default !important;
}

.textarea {
    margin-top: 10px !important;

    &_tooltip {
        font-size: 10px;
        white-space: pre-wrap;
        color: black;
    }
}

.form {
    &_checkbox {
        & :global {
            & .ant-radio-inner {
                width: 35px;
                height: 35px;

                &::after {
                    width: 10px;
                    height: 12px;
                }
            }

            & .ant-checkbox-inner {
                width: 35px;
                height: 35px;

                &::after {
                    width: 9px;
                    height: 18px;
                }
            }

            & .ant-radio,
            & .ant-checkbox {
                font-size: 20px;
            }
        }
    }
    &_input {
        margin-bottom: 0 !important;
    }
}

.button {
    margin-top: 20px;
    width: 240px !important;
    height: 48px !important;
}
