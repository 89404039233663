.popup {
  width: 500px;
  height: auto;
  position: relative;
  padding-top: 40px;
  padding-left: 40px;
  word-break: break-word;
}

.popupContent {
  margin-right: 40px;
}

.popupClose {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.popupTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 40px;
}

.button {
  margin-bottom: 40px;
  margin-top: 40px;
  width: 149px;
  height: 48px;
  background: #000000;
  border-radius: 6px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dash {
  display: block;
  max-width: 10px;
  height: 1px;
  margin: 16px;
  background: #c1c1c1;
}

.wrapper > input {
  text-align: center;
  width: 100px;
  height: 40px;
  background: #F2F2F2;
  border-radius: 6px;
  border: none;
  padding: 15px;
}

.wrapper input:focus {
  outline: none;
}