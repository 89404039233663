.button {
    width: 120px;
    margin-left: 38px;
    height: 40px;
    background: #000000;
    border-radius: 6px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
